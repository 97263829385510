import React, { useEffect, useState } from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Button, Chip, Grid, IconButton, TextField, Typography, Box, List, ListItem, ListItemText, DialogContent } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import logo from '../img/logo.png'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TableContainer, Paper, Dialog, DialogActions, DialogTitle } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'jspdf-autotable';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function Payout() {
    let url;
    if (process.env.NODE_ENV === 'production') {
        url = "https://www.multiverse-funding.com";
    } else {
        url = "https://www.multiverse-funding.com";
        // url = "http://localhost:8080";
    }

    const [pdfUrl, setPdfUrl] = useState('');
    const statusOrder = ["pending", "paid", "denied"]; // Define the order of status sorting

    function getStatusColor(status) {
        switch (status) {
            case "paid":
                return "success";
            case "pending":
                return "warning";
            case "denied":
                return "error";
            default:
                return "default";
        }
    }
    const [reviews, setReviews] = useState([]);
    const [filter, setFilter] = useState('');
    const [sortCycle, setSortCycle] = useState(0); // State to keep track of sorting cycle

    const handleSort = () => {
        const nextSortCycle = (sortCycle + 1) % statusOrder.length; // Cycle through the sort order
        setSortCycle(nextSortCycle);
        const nextStatus = statusOrder[nextSortCycle];
        setReviews(prevReviews => [...prevReviews].sort((a, b) => {
            if (a.status === nextStatus) return -1;
            if (b.status === nextStatus) return 1;
            return 0;
        }));
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setFilter(value);
    };

    const filteredReviews = reviews.filter(review =>
        review.email.toLowerCase().includes(filter) || review.accountId.includes(filter)
    );

    const [refresh, setRefresh] = useState(false)

    const [token, setToken] = useState(null);

    useEffect(() => {
        // Retrieve user session from localStorage
        const userSession = JSON.parse(localStorage.getItem("userSession"));
        if (userSession && userSession.token) {
            setToken(userSession.token);
        } else {
            console.log("No user session found");
            alert("No user session found");
        }
    }, []); // Runs only on component mount

    useEffect(() => {
        if (!token) return; // Prevent API call if no token is set

        axios.get(`${url}/api/get-all-withdrawals`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setReviews(res.data.list);
            })
            .catch(err => {
                console.error(err);
            });
    }, [token, refresh]); // Runs when `token` or `refresh` updates






    const [AccountDetails, setAccountDetails] = useState({})
    const [payoutDetails, setPayoutDetails] = useState({
        paymentMethod: '',
        paymentAddress: '',
    });
    const [ConsistencyRange_Deduction, setConsistencyRange_Deduction] = useState(0);
    const [Rule35_Deduction, setRule35_Deduction] = useState(0);
    const [Otherss_Deduction, setOtherss_Deduction] = useState(0);
    const [Total_Deduction, setTotal_Deduction] = useState(0);
    const [profitSplit, setProfitSplit] = useState(null);
    const [PayoutAmount, setPayoutAmount] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0)
    const [comment, setComment] = useState("")
    const [reason, setReason] = useState("")

    const paymentdetails = (address, method) => {
        setPayoutDetails({
            ...payoutDetails,
            paymentMethod: method,
            paymentAddress: address
        })
    };

    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text);
    };


    const [ConsistencyRange, setConsistencyRange] = useState([]);
    const [Rule35, set35Rule] = useState([]);
    const [Otherss, setOtherss] = useState([]);

    const handleSubmit = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const imgWidth = 50;
        // Calculate the X coordinate to center the image
        const xCoordinate = (pageWidth / 2) - (imgWidth / 2);
        // Add logo image
        doc.addImage(logo, 'PNG', xCoordinate, 10, 50, 50);

        const fontSize = 12;
        doc.setFontSize(fontSize);
        // Add centered text at the new Y position
        doc.text("Multiverse Funding", xCoordinate + 7, 60);
        // Add body text
        doc.setFontSize(10);
        doc.text(`Name: ${AccountDetails.accountName} | Account Size: ${formatMoney(AccountDetails.InitialBalance)} ${AccountDetails.accountType} | MultiTrader ID: ${AccountDetails.accountId}`, 15, 90);
        doc.text(`Total Profit: ${formatMoney(AccountDetails.CurrentBalance - AccountDetails.InitialBalance)}`, 15, 100);
        doc.text(`Deductions: -$${Total_Deduction}`, 15, 110);
        doc.text(`Profit Split: ${profitSplit}%`, 15, 120);
        doc.text(`Payout method: ${payoutDetails.paymentMethod}`, 15, 130);
        doc.text(`Payout Address: ${payoutDetails.paymentAddress}`, 15, 140);
        // Commission
        doc.setFontSize(16);
        doc.text(`Your commission: $${PayoutAmount.toFixed(2)}`, 15, 160);
        // Footer
        doc.setFontSize(10);
        doc.addImage(logo, 'PNG', 15, 200, 30, 30);
        doc.text('Payout Department', 15, 230);
        doc.text('Multiverse Funding', 15, 240);
        doc.text('payout@multiverse-funding.com', 15, 250);
        doc.text('www.multiverse-funding.com', 15, 260);
        // Add a new page
        doc.addPage();
        doc.setFontSize(14);
        doc.text("Consistency Range", 105, 10, { align: 'center' });
        doc.autoTable({
            head: [["Trade ID", "Trade Outcome"]],
            body: ConsistencyRange,
            startY: 15,
            theme: 'striped',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [22, 160, 133] },
            margin: { horizontal: 10 },
            bodyStyles: { valign: 'top' },
            didDrawPage: function (data) {
                // Add header or footer
            },
        });
        doc.setFontSize(14);
        doc.text("35% Rule", 105, 75, { align: 'center' });
        doc.autoTable({
            head: [["Trade ID", "Trade Outcome"]],
            body: Rule35,
            startY: 80,
            theme: 'striped',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [22, 160, 133] },
            margin: { horizontal: 10 },
            bodyStyles: { valign: 'top' },
            didDrawPage: function (data) {
                // Add header or footer
            },
        });
        doc.text("Copy Trading", 105, 125, { align: 'center' });
        doc.autoTable({
            head: [["Trade ID", "Trade Outcome"]],
            body: Otherss,
            startY: 130,
            theme: 'striped',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [22, 160, 133] },
            margin: { horizontal: 10 },
            bodyStyles: { valign: 'top' },
            didDrawPage: function (data) {
                // Add header or footer
            },
        });
        doc.setFontSize(12);
        doc.text('If any of your trades have violated our trading policies, please ensure that you carefully review the FAQ section detailing the first-step evaluation rules for future payout considerations. Additionally, ensure that you utilize the provided tool to calculate your lot size consistency range accurately.', 15, 180, {
            maxWidth: 180,
            align: 'justify'
        });
        doc.textWithLink('https://www.multiverse-funding.com/Lotsize', 15, 200, {
            url: 'https://www.multiverse-funding.com/Lotsize',
            maxWidth: 180,
            align: 'left'
        });
        // Footer
        doc.setFontSize(10);
        doc.addImage(logo, 'PNG', 15, 200, 30, 30);
        doc.text('Payout Department', 15, 230);
        doc.text('Multiverse Funding', 15, 240);
        doc.text('payout@multiverse-funding.com', 15, 250);
        doc.text('www.multiverse-funding.com', 15, 260);
        const pdfBlob = doc.output('blob');
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
        return pdfBlob;
    };

    // Effect to update PDF content in real-time as inputs change
    useEffect(() => {
        handleSubmit();
        return () => {
            URL.revokeObjectURL(pdfUrl);
        };
    }, [payoutDetails, AccountDetails, ConsistencyRange, profitSplit, Rule35, PayoutAmount, Otherss]);


    const handleReviewStatus = async (review) => {
        const userSession = JSON.parse(localStorage.getItem("userSession"));
        const token = userSession ? userSession.token : null;
    
        if (!token) {
            console.log("No user session found");
            alert("You are not authenticated. Please log in again.");
            return;
        }
    
        if (review.status === "pending") {
            try {
                // Fetch trading account details
                const accountResponse = await axios.post(
                    `${url}/api/find-trading-acccount`,
                    { accountId: review.accountId },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                console.log(accountResponse);
                setAccountDetails(accountResponse.data);
    
                // Fetch user by email and update payment details
                const userResponse = await axios.post(
                    `${url}/api/find-user-by-email`,
                    { email: review.email },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                paymentdetails(userResponse.data[0].paymentAddress, userResponse.data[0].paymentMethod);
            } catch (err) {
                console.error("Error during data retrieval:", err);
            }
        }
    };
    


    const [selectedHistory, setSelectedHistory] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [denydialog, setDenydialog] = useState(false);
    const [approvedialog, setApprovedialog] = useState(false);


    const handleRowClick = (history) => {
        setSelectedHistory(history);
        setDialogOpen(true);
    };

    const handleDialogClose = (option) => {
        if (selectedHistory) {
            if (option == "Consistency Range") {
                setConsistencyRange([...ConsistencyRange, [selectedHistory.positionId, selectedHistory.profit]]);
            } else if (option == "35% Rule") {
                set35Rule([...Rule35, [selectedHistory.positionId, selectedHistory.profit]]);
            }
            else if (option == "Others") {
                setOtherss([...Otherss, [selectedHistory.positionId, selectedHistory.profit]]);
            }
        }
        setDialogOpen(false);
    };
    const handleDenydialog = (option) => {
        if (option == "deny") {
            if (reason !== "" && comment !== "") {
                sendDeniedPDFToServer(AccountDetails.accountEmail, reason, comment)
            }

        }
        setDenydialog(false);
    };
    const handleApprovedialog = (option) => {
        if (option == "approve") {
            sendPDFToServer(AccountDetails.accountEmail)
        }
        setApprovedialog(false);
    };


    //approve payout function
    async function sendPDFToServer(email) {
        const userSession = JSON.parse(localStorage.getItem("userSession"));
        const token = userSession ? userSession.token : null;
    
        if (!token) {
            console.log("No user session found");
            alert("You are not authenticated. Please log in again.");
            return;
        }
    
        const formData = new FormData();
        const pdfBlob = handleSubmit();
    
        formData.append('pdf', pdfBlob, 'example.pdf');
        formData.append('email', email);
    
        try {
            const response = await axios.post(`${url}/api/approve-payment`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
            console.log('Server responded: ', response.data);
        } catch (error) {
            console.error('Error sending PDF:', error);
        }
    }
    


    //deny payout function
    async function sendDeniedPDFToServer(email, reason, comment) {
        const userSession = JSON.parse(localStorage.getItem("userSession"));
        const token = userSession ? userSession.token : null;
    
        if (!token) {
            console.log("No user session found");
            alert("You are not authenticated. Please log in again.");
            return;
        }
    
        const formData = new FormData();
        const pdfBlob = handleSubmit();
    
        formData.append('pdf', pdfBlob, 'example.pdf');
        formData.append('email', email);
        formData.append('reason', reason);
        formData.append('comment', comment);
    
        try {
            const response = await axios.post(`${url}/api/deny-payment`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            });
            console.log('Server responded: ', response.data);
        } catch (error) {
            console.error('Error sending PDF:', error);
        }
    }
    
    const [isHFTSeries, setIsHFTSeries] = useState(false);
    const [tradingDays, setTradingDays] = useState(false);
    const [drawdown, setDrawdown] = useState(false);
    const [forbidenStrategy, setForbidenStrategy] = useState(false);
    const [rule35, setRule35] = useState(false);
    const [consistency, setConsistency] = useState(false);
    const handleIsHFT = (event) => {
        setIsHFTSeries(event.target.checked);

    };
    const TradingDays = (event) => {
        setTradingDays(event.target.checked);
        if (event.target.checked) {
            setReason("minimum trading days required")
        } else {
            setReason("")
        }
    };
    const Drawdown = (event) => {
        setDrawdown(event.target.checked);
        if (event.target.checked) {
            setReason("minimum trading days required")
        } else {
            setReason("")
        }
    };
    const ForbidenStrategy = (event) => {
        setForbidenStrategy(event.target.checked);
        if (event.target.checked) {
            setReason("use of forbitten strategy")
        } else {
            setReason("")
        }
    };
    const Rule35_func = (event) => {
        setRule35(event.target.checked);
        if (event.target.checked) {
            setReason("35% rule breached")
        } else {
            setReason("")
        }
    };
    const Consistency = (event) => {
        setConsistency(event.target.checked);
        if (event.target.checked) {
            setReason("consistency range breached")
        } else {
            setReason("")
        }
    };
    const [others, setOthers] = useState(false)
    const Others = (event) => {
        setOthers(event.target.checked);
        if (event.target.checked) {
            setReason(".")
        } else {
            setReason("")
        }
    };

    useEffect(() => {
        // Only calculate total profit if there is at least one entry in the array
        if (ConsistencyRange.length > 0) {
            const sum = ConsistencyRange.reduce((acc, item) => {
                if (!isNaN(parseInt(item[1]))) {
                    return acc + parseFloat(item[1]);
                }
                return acc;
            }, 0);
            setConsistencyRange_Deduction(sum);
        } else {
            setConsistencyRange_Deduction(0);
        }
    }, [ConsistencyRange]);

    useEffect(() => {
        // Only calculate total profit if there is at least one entry in the array
        if (Rule35.length > 0) {
            const sum = Rule35.reduce((acc, item) => {
                if (!isNaN(parseInt(item[1]))) {
                    return acc + parseFloat(item[1]);
                }
                return acc;
            }, 0);
            setRule35_Deduction(sum);
        } else {
            setRule35_Deduction(0);
        }
    }, [Rule35]);

    useEffect(() => {
        // Only calculate total profit if there is at least one entry in the array
        if (Otherss.length > 0) {
            const sum = Otherss.reduce((acc, item) => {
                if (!isNaN(parseInt(item[1]))) {
                    return acc + parseFloat(item[1]);
                }
                return acc;
            }, 0);
            setOtherss_Deduction(sum);
        } else {
            setOtherss_Deduction(0);
        }
    }, [Otherss]);

    useEffect(() => {
        setTotal_Deduction(Rule35_Deduction + ConsistencyRange_Deduction + Otherss_Deduction)
    }, [Rule35_Deduction, ConsistencyRange_Deduction, Otherss_Deduction]);

    useEffect(() => {
        setTotalProfit(AccountDetails.CurrentBalance - AccountDetails.InitialBalance)
    }, [AccountDetails]);

    useEffect(() => {
        if (profitSplit !== null) {
            let net_amount = totalProfit - Total_Deduction
            setPayoutAmount((net_amount * profitSplit) / 100)
        }
    }, [profitSplit, Total_Deduction]);
    return (
        <div>
            <Grid container spacing={2} sx={{ padding: 4 }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Search by Email or Account ID"
                        variant="outlined"
                        onChange={handleSearchChange}
                        sx={{ mb: 2 }}
                    />
                    <Card raised sx={{ maxWidth: '100%' }}>
                        <Button onClick={() => { setRefresh(!refresh) }} variant='outlined'>Refresh</Button>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Account ID</TableCell>
                                        <TableCell>Payment Method</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>
                                            Status <IconButton onClick={handleSort}><SortIcon /></IconButton>
                                        </TableCell>
                                        <TableCell>Request Time</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredReviews.map((review, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{review.email}</TableCell>
                                            <TableCell>{review.accountId}</TableCell>
                                            <TableCell>{review.paymentMethod}</TableCell>
                                            <TableCell>{review.amount}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={review.status}
                                                    color={getStatusColor(review.status)}
                                                    size="small"
                                                    sx={{ width: "70px" }}
                                                />
                                            </TableCell>
                                            <TableCell>{convertToHumanReadable(review.requestDate)}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color={review.status === "paid" ? "success" : (review.status === "pending" ? "primary" : "error")}
                                                    onClick={() => { handleReviewStatus(review) }}
                                                >
                                                    {review.status === "paid" || review.status === "denied" ? "View" : "Review"}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


            <Grid container spacing={2} sx={{ padding: 4 }}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Review Payout Details</Typography>
                            <Box component="form" noValidate autoComplete="off">
                                <List>
                                    <ListItem divider>
                                        <ListItemText primary={`Full Name: ${AccountDetails.accountName !== undefined ? AccountDetails.accountName : ""}`} />
                                    </ListItem>
                                    <ListItem divider>
                                        <ListItemText primary={`Account ID: ${AccountDetails.accountId !== undefined ? AccountDetails.accountId : ""}`} />
                                    </ListItem>
                                    <ListItem divider>
                                        <ListItemText primary={`Account Size: ${formatMoney(AccountDetails.InitialBalance)}`} />
                                    </ListItem>
                                    <ListItem divider>
                                        <ListItemText primary={`Account Type: ${AccountDetails.accountType !== undefined ? AccountDetails.accountType : ""}`} />
                                    </ListItem>
                                    <ListItem divider>
                                        <ListItemText primary={`Total Profit: ${formatMoney(AccountDetails.CurrentBalance - AccountDetails.InitialBalance)}`} />
                                    </ListItem>
                                    <ListItem divider>
                                        <ListItemText primary={`Payment Method: ${payoutDetails.paymentMethod}`} />
                                    </ListItem>
                                    <ListItem divider>
                                        <ListItemText primary={`Payment Address: ${payoutDetails.paymentAddress}`} />
                                        <IconButton onClick={() => handleCopyClick(payoutDetails.paymentAddress)} edge="end">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </ListItem>



                                </List>
                                <TextField label="Deduction" name="deduction" fullWidth margin="normal" value={Total_Deduction} onChange={(e) => { setTotal_Deduction(e.target.value) }} />
                                <TextField label="Profit Split(%)" name="profitSplit" fullWidth margin="normal" value={profitSplit} onChange={(e) => { setProfitSplit(e.target.value) }} />
                                <List>
                                    <ListItem divider>
                                        <ListItemText primary={`Payout Amount: $ ${PayoutAmount}`} />
                                    </ListItem>
                                </List>
                                <div>
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox checked={isHFTSeries} onChange={handleIsHFT} />}
                                            label="Is HFT Series"
                                        />
                                        {isHFTSeries ? <div>
                                            <Button onClick={() => { setConsistencyRange([]) }} variant='outlined' style={{ marginRight: "5px" }} color='error'>Reset Consistency Range</Button>
                                            <Button onClick={() => { set35Rule([]) }} variant='outlined' style={{ margin: "5px" }} color='primary'>Reset 35% rule</Button>
                                        </div> : ""}
                                    </div>

                                </div>
                                <Button onClick={() => { setDenydialog(true) }} variant='contained' style={{ marginRight: "5px" }} color='error'>Deny Payout</Button>
                                <Button onClick={() => { setApprovedialog(true) }} variant='contained' style={{ margin: "5px" }} color='primary'>Approve payout</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ padding: 4 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Position ID</TableCell>
                                <TableCell>Symbol</TableCell>
                                <TableCell>Lot</TableCell>
                                <TableCell>Entry Price</TableCell>
                                <TableCell>Closing Price</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Stop Loss</TableCell>
                                <TableCell>Take Profit</TableCell>
                                <TableCell>Opening Time</TableCell>
                                <TableCell>Closing Time</TableCell>
                                <TableCell>Profit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {AccountDetails && AccountDetails.tradingHistory ? AccountDetails.tradingHistory.map((row) => (
                                <TableRow key={row.positionId} hover onClick={() => handleRowClick(row)}>
                                    <TableCell>{row.positionId}</TableCell>
                                    <TableCell>{row.symbol}</TableCell>
                                    <TableCell>{row.lot}</TableCell>
                                    <TableCell>{row.entryPrice}</TableCell>
                                    <TableCell>{row.closingPrice}</TableCell>
                                    <TableCell>{row.action}</TableCell>
                                    <TableCell>{row.stopLoss}</TableCell>
                                    <TableCell>{row.takeProfit}</TableCell>
                                    <TableCell>{convertToHumanReadable(row.timestamp)}</TableCell>
                                    <TableCell>{convertToHumanReadable(row.closingTime)}</TableCell>
                                    <TableCell>{formatMoney(row.profit)}</TableCell>
                                </TableRow>
                            )) : ""}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog open={dialogOpen} onClose={() => handleDialogClose(null)}>
                    <DialogTitle>Assign to Rule Violation</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => handleDialogClose('Consistency Range')}>Consistency Range</Button>
                        <Button onClick={() => handleDialogClose('35% Rule')}>35% Rule</Button>
                        <Button onClick={() => handleDialogClose('Others')}>Others</Button>
                    </DialogActions>
                </Dialog>

                <BootstrapDialog
                    onClose={() => handleDenydialog(null)}
                    aria-labelledby="customized-dialog-title"
                    open={denydialog}

                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Denying Payout
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleDenydialog(null)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent style={{ minWidth: 300, maxWidth: 1000 }} dividers>
                        <Typography>Denial Reason</Typography>
                        <FormControlLabel
                            control={<Checkbox checked={tradingDays} onChange={TradingDays} />}
                            label="insufficient Minimum trading days"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={drawdown} onChange={Drawdown} />}
                            label="Drawdown"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={forbidenStrategy} onChange={ForbidenStrategy} />}
                            label="Forbiden strategy"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={rule35} onChange={Rule35_func} />}
                            label="35% rule"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={consistency} onChange={Consistency} />}
                            label="Consistency range"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={others} onChange={Others} />}
                            label="others"
                        />
                        <TextField
                            id="outlined-multiline-flexible"
                            label="comment(required)"
                            multiline
                            fullWidth
                            onChange={(e) => { setComment(e.target.value) }}
                            value={comment}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' autoFocus onClick={() => handleDenydialog('deny')}>
                            Deny
                        </Button>
                    </DialogActions>
                </BootstrapDialog>


                <BootstrapDialog
                    onClose={() => handleApprovedialog(null)}
                    aria-labelledby="customized-dialog-title"
                    open={approvedialog}

                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Approving Payout
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => handleApprovedialog(null)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent style={{ minWidth: 300, maxWidth: 1000 }} dividers>
                        <TextField
                            id="outlined-multiline-flexible"
                            label="comment(optional)"
                            multiline
                            fullWidth
                            onChange={(e) => { setComment(e.target.value) }}
                            value={comment}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => handleApprovedialog('approve')}>
                            Approve
                        </Button>
                    </DialogActions>
                </BootstrapDialog>

            </Grid>

            <Grid container spacing={2} sx={{ padding: 4 }}>
                <Grid item xs={12}>
                    <Card raised sx={{ maxWidth: '100%' }}>
                        <CardContent>
                            {pdfUrl && (
                                <iframe src={pdfUrl} width="100%" height="600px" title="PDF Preview"></iframe>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


        </div>
    )
}

function convertToHumanReadable(isoDateString) {
    const date = new Date(isoDateString);
    const humanReadableDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'short'
    });
    const humanReadableTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });
    return `${humanReadableDate} at ${humanReadableTime}`;
}

function formatMoney(amount) {
    // Check if the amount is provided and is a number
    if (amount === null || amount === undefined || typeof amount !== 'number' || isNaN(amount)) {
        return 0;  // Return a default message or handle as needed
    }

    // Proceed with formatting if the checks pass
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',  // Adjust currency as necessary
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
}