import React, { useState, useEffect } from 'react';
import Localbase from 'localbase'
import { CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './Sidebar';
import Overview from './Overview';
import Footer from '../footer/Footer';
import Nav from '../navbar/Nav';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Plans from './Plans';
import CreateAccount from './CreateAccount';
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import Certificate from './Certificate'
import Users from './Users'
import TradingAccount from './TradingAccount'
import KYCForm from './KYCForm'
import Withdraw from './Withdraw'
import Setting from './Setting'
import Position from './Position'
import Payout from './Payout'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import ReviewKYC from './ReviewKYC';
import Upgrade from './Upgrade';
import HFTPage from './HFTPage'
import Promo from './Promo'
import Partner from './Partner'
const drawerWidth = 240;
function Dashboard() {
    const [data, setData] = React.useState("");
    useEffect(() => {
        window.scrollTo(0, 0)
        if (data._id) {
            setdisplayContent('Overview');
        }
    }, [data]);

    let URL;
    if (process.env.NODE_ENV == 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
        // URL = "https://www.multiverse-funding.com";
    }
    const [showPassword, setShowPassword] = React.useState(false);
    //hook for login or sign up 
    //TODO: EDIT BEFORE PRODUCTION
    const [login, setLogin] = React.useState(true);
    //show pw/hide
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //login credetentials
    const [email, setEmail] = React.useState("")
    const [country, setCountry] = React.useState("")
    const [FirstName, setFirstName] = React.useState("")
    const [LastName, setLastName] = React.useState("")
    const [password, setpassword] = React.useState("")
    const [username, setUsername] = React.useState("")
    const [IsEmpty, setIsEmpty] = React.useState(false)
    const [otp, setOtp] = React.useState()

    //loading screen 
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    // const timer = React.useRef();

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleButtonClick = () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
        }
    };

    const [open, setOpen] = React.useState(false);
    const [opensuccess, setOpensuccess] = React.useState(false);
    const [openOTP, setOpenOTP] = React.useState(false);
    const [openUSER, setOpenUSER] = React.useState(false);
    const [openErrorText, setOpenErrorText] = React.useState(false);
    const [openEmailError, setOpenEmailError] = React.useState(false);
    const [errorRendered, setOpenerrorRendered] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleCloseEmailError = () => {
        setOpenEmailError(false);
    };
    const handleClickOpenUSER = () => {
        setOpenUSER(true);
    };
    const handleClickOpensuccess = () => {
        setOpensuccess(true);
    };
    const handleClickOpenOTP = () => {
        setOpenOTP(true);
    };
    const handleClickErrorText = () => {
        setOpenErrorText(true);
    };
    const handleClickEmailError = () => {
        setOpenEmailError(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClosesuccess = () => {
        setOpensuccess(false);
    };
    const handleCloseOTP = () => {
        setOpenOTP(false);
    };
    const handleCloseUSER = () => {
        setOpenUSER(false);
    };
    const handleCloseErrorText = () => {
        setOpenErrorText(false);
    };
    const ErrorText = () => {
        return (
            <Dialog
                open={openErrorText}
                onClose={handleCloseErrorText}
            >
                <DialogContent>
                    <DialogContentText>
                        {errorRendered}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleCloseErrorText()
                    }}>OKAY</Button>
                </DialogActions>
            </Dialog>
        )
    }
    const [dashboard, setDashboard] = React.useState(false)
    const [openSideBar, setopenSideBar] = useState(false);
    const handleDrawerToggle = () => {
        setopenSideBar(!openSideBar);
    };
    const [displayContent, setdisplayContent] = useState('Overview');
    const handleDataFromChild = (data) => {
        console.log('Data received from child:', data);
        setdisplayContent(data);
    };
    useEffect(() => {
        handleDrawerToggle()
    }, [displayContent]);


    let db = new Localbase('Mfundingdashboard');
    useEffect(() => {
        db.collection('user').get().then(users => {
            if (users.length !== 0) {
                const currentTime = new Date().getTime();
                const loginTimestamp = users[0]?.loginTimestamp;

                if (loginTimestamp && currentTime - loginTimestamp <= 5 * 60 * 10000) {
                    setDashboard(true); // Valid session
                } else {
                    db.collection('user').delete(); // Clear expired session
                    localStorage.removeItem('userSession'); // Clear localStorage
                    setDashboard(false);
                }
            } else {
                setDashboard(false);
            }
        });
    }, []);


    const handleSignup = async () => {
        try {
            setLoading(true); // Assuming setLoading changes the loading state
            const response = await axios.post(`${URL}/api/dashboard-sign-up`, {
                email,
                password,
                otp,
                firstName: FirstName,
                lastName: LastName,
                country,
                DOB: ""
            });

            if (response.data.success) {
                // Reset form and state on successful signup
                setEmail("");
                setpassword("");
                setSuccess(true);
                handleClickOpensuccess(); // Assuming this handles successful signup UI response
            } else {
                alert("Something went wrong during signup.");
            }
        } catch (error) {
            handleSignupError(error);
        } finally {
            setLoading(false); // Ensure loading state is reset
            handleClose(); // Assuming this handles closing the modal or form
        }
    }

    function handleSignupError(error) {
        const errorMessage = error.response?.data?.message;
        if (errorMessage) {
            switch (errorMessage) {
                case "The OTP is not valid":
                    handleClickOpenOTP(); // Handle OTP error
                    break;
                case "User already exists":
                    handleClickOpenUSER(); // Handle existing user error
                    break;
                default:
                    alert(`Signup Error: ${errorMessage}`);
                    break;
            }
        } else {
            console.error(error);
            alert("An unexpected error occurred during signup.");
        }
    }

    const [opensnack, setopensnack] = useState(false);
    const [message, setMessage] = useState('');

    const handleClick = (customMessage) => {
        setMessage(customMessage);
        setopensnack(true);
    };

    const handleClosesnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setopensnack(false);
    };

    return (
        <div className='contain-dh'>
            {dashboard === false ? <div >
                <Nav />
                <div className='contain-affliate' >
                    <div class="card">
                        <div class="content">
                            {login === true ?
                                <div class="back">
                                    <div class="back-content">
                                        <h1>Login to Dashboard</h1>
                                        <div className='login-field-affliate'>
                                            <AccountCircle sx={{ color: IsEmpty ? "red" : "white", mr: 1, my: 0.5, width: "10%" }} />
                                            <input value={email} onChange={(e) => { setEmail(e.target.value.toLowerCase().trim()) }} className='affliate-email-login' placeholder="Email" variant="outlined" />
                                        </div>
                                        <div className='login-field-affliate'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                sx={{ color: IsEmpty ? "red" : "white", mr: 1, my: 0.5, width: "10%" }}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            <input value={password} onChange={(e) => { setpassword(e.target.value) }} className='affliate-pw-login' type={showPassword ? 'text' : 'password'} placeholder="Password" variant="outlined" />
                                            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', m: 1, width: "19%" }}>
                                                <Button
                                                    sx={buttonSx}
                                                    disabled={loading} className='login-btn-affliate' variant="contained"
                                                    onClick={() => {
                                                        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                        if (email.match(validRegex) && password) { // Ensure both email and password are provided
                                                            handleButtonClick();
                                                            setIsEmpty(false);
                                                            axios.post(`${URL}/api/dashboard-login`, { email, password })
                                                                .then((response) => {

                                                                    if (response.data.success) {

                                                                        // Set session states
                                                                        setDashboard(true);
                                                                        setSuccess(true);
                                                                        setLoading(false);

                                                                        const loginTimestamp = new Date().getTime();

                                                                        // Add user to Localbase with timestamp
                                                                        db.collection('user').add({
                                                                            ...response.data.user.user, // Include user data from server
                                                                            token: response.data.user.token, // JWT token
                                                                            loginTimestamp: loginTimestamp // Add timestamp for session tracking
                                                                        });

                                                                        // Save session info to localStorage
                                                                        localStorage.setItem('userSession', JSON.stringify({
                                                                            user: response.data.user.user, // User data
                                                                            token: response.data.user.token, // JWT token
                                                                            loginTimestamp: loginTimestamp // Timestamp for session expiry check
                                                                        }));

                                                                        // Optionally clear session after 5 minutes
                                                                        setTimeout(() => {
                                                                            localStorage.removeItem('userSession'); // Clear from localStorage
                                                                            db.collection('user').delete(); // Clear Localbase session data
                                                                            setDashboard(false);
                                                                            console.log('Session expired');
                                                                        }, 5 * 60 * 10000); // 5 minutes
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.error('Error:', error.response);
                                                                    const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
                                                                    handleClick(errorMessage);
                                                                    setLoading(false);
                                                                });
                                                        } else {
                                                            setIsEmpty(true);
                                                        }
                                                    }}


                                                >Login</Button>
                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: green[500],
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Box>

                                        </div>
                                        <Link to='/reset'>password forgot</Link>
                                        <Snackbar
                                            open={opensnack}
                                            autoHideDuration={3000} // Alert will disappear after 6000ms (6 seconds)
                                            onClose={handleClosesnack}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        >
                                            <Alert onClose={handleClosesnack} severity="error" sx={{ width: '100%' }}>
                                                {message}
                                            </Alert>
                                        </Snackbar>
                                        <Button onClick={() => {
                                            setLogin(!login)
                                            setEmail("")
                                            setpassword("")
                                        }} className='choice-btn-affliate' variant="contained">Create an acccount</Button>
                                    </div>
                                </div> :
                                <div class="back">
                                    <div class="back-content">

                                        <h1>Create an Account</h1>
                                        <div className='login-field-affliate'>
                                            <AccountCircle sx={{ color: 'white', mr: 1, my: 0.5, width: "10%" }} />
                                            <input value={FirstName} onChange={(e) => { setFirstName(e.target.value.trim().toLocaleLowerCase()) }} className='affliate-email-login' placeholder="First Name" variant="outlined" />
                                        </div>
                                        <div className='login-field-affliate'>
                                            <AccountCircle sx={{ color: 'white', mr: 1, my: 0.5, width: "10%" }} />
                                            <input value={LastName} onChange={(e) => { setLastName(e.target.value.trim().toLocaleLowerCase()) }} className='affliate-email-login' placeholder="Last Name" variant="outlined" />
                                        </div>
                                        <div className='login-field-affliate'>
                                            <EmailIcon sx={{ color: 'white', mr: 1, my: 0.5, width: "10%" }} />
                                            <input value={email} onChange={(e) => { setEmail(e.target.value.trim().toLocaleLowerCase()) }} className='affliate-email-login' placeholder="Email" variant="outlined" />
                                        </div>
                                        <div className='login-field-affliate'>
                                            <PublicIcon sx={{ color: 'white', mr: 1, my: 0.5, width: "10%" }} />
                                            <input value={country} onChange={(e) => { setCountry(e.target.value.trim()) }} className='affliate-email-login' placeholder="Country" variant="outlined" />
                                        </div>
                                        <div className='login-field-affliate'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                sx={{ color: 'white', mr: 1, my: 0.5, width: "10%" }}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            <input value={password} onChange={(e) => { setpassword(e.target.value.trim()) }} className='affliate-pw-login' type={showPassword ? 'text' : 'password'} placeholder="Password" variant="outlined" />
                                            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', m: 1, width: "19%" }}>
                                                <Button
                                                    sx={buttonSx}
                                                    disabled={loading} className='login-btn-affliate' variant="contained"
                                                    onClick={() => {
                                                        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                                        if (email.match(validRegex) === null) {
                                                            setOpenerrorRendered("email invalid")
                                                            handleClickErrorText()
                                                        } else if (password.length < 6) {
                                                            setOpenerrorRendered("password should have more than 6 characters")
                                                            handleClickErrorText()
                                                        } else if (FirstName === "" || LastName === "") {
                                                            setOpenerrorRendered("Please enter First name && Last name")
                                                            handleClickErrorText()
                                                        } else {
                                                            handleButtonClick()
                                                            axios.post(`${URL}/api/dashboard-send-otp`, { email })
                                                                .then(e => {
                                                                    if (e.data.success === true) {
                                                                        handleClickOpen()
                                                                    }
                                                                })
                                                                .catch(e => {
                                                                    console.log(e)
                                                                    if (e.response.data.message === "User is already registered") {
                                                                        handleClickEmailError()
                                                                    }
                                                                    setSuccess(true);
                                                                    setLoading(false);


                                                                })

                                                        }
                                                    }}
                                                >Sign Up</Button>
                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: green[500],
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </div>
                                        <Button onClick={() => {
                                            setLogin(!login)
                                            setEmail("")
                                            setpassword("")
                                        }} className='choice-btn-affliate' variant="contained">Login to Dashboard</Button>


                                    </div>
                                </div>}
                        </div>
                    </div>


                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}>
                    <DialogTitle>OTP</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter the 6 digits code sent to your email account({email})
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="name"
                            name="email"
                            label="OTP"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={otp}
                            onChange={(e) => { setOtp(e.target.value) }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleClose();
                            setSuccess(true);
                            setLoading(false);
                        }}>Cancel</Button>
                        <Button onClick={() => {
                            handleSignup()

                        }}>Confirm</Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={opensuccess}
                    onClose={handleClosesuccess}
                >
                    <DialogContent>
                        <DialogContentText>
                            Account Created, proceed to login
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setLogin(!login); handleClosesuccess() }}>Login</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openEmailError}
                    onClose={handleCloseEmailError}
                >
                    <DialogContent>
                        <DialogContentText>
                            Email already registered, Use another Email Account
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setEmail(""); setpassword(""); handleCloseEmailError(); setUsername("") }}>Okay</Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={openOTP}
                    onClose={handleCloseOTP}
                >
                    <DialogContent>
                        <DialogContentText>
                            The OTP is not valid, request a new one
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleClosesuccess()
                            handleClose();
                            handleCloseOTP()
                            setEmail("")
                            setpassword("")
                            setUsername("")
                            setSuccess(true);
                            setLoading(false);
                            setOtp()
                        }}>OKAY</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openUSER}
                    onClose={handleCloseUSER}
                >
                    <DialogContent>
                        <DialogContentText>
                            Username already exist, try another one
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleClosesuccess()
                            handleClose();
                            handleCloseOTP()
                            setpassword("")
                            setUsername("")
                            setSuccess(true);
                            setLoading(false);
                            setOtp()
                            handleCloseUSER()
                        }}>OKAY</Button>
                    </DialogActions>
                </Dialog>
                <ErrorText />
            </div> :
                <div>
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, ...(openSideBar && { display: 'none' }) }}
                            style={{ marginLeft: "10px" }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Sidebar onData={handleDataFromChild} openSideBar={openSideBar} handleDrawerToggle={handleDrawerToggle} />
                    </Box>
                    <div className='dashboard-contain' style={{ marginLeft: openSideBar ? `${drawerWidth}px` : '0px', transition: 'margin 0.5s ease' }}>
                        {displayContent === "Overview" ? <Overview receivedData={data} /> : ""}
                        {displayContent === "Plans" ? <Plans /> : ""}
                        {displayContent === "New" ? <CreateAccount /> : ""}
                        {displayContent === "Certificate" ? <Certificate /> : ""}
                        {displayContent === "Users" ? <Users /> : ""}
                        {displayContent === "TradingAccount" ? <TradingAccount sendData={setData} /> : ""}
                        {displayContent === "KYC" ? <KYCForm /> : ""}
                        {displayContent === "Withdrawal" ? <Withdraw /> : ""}
                        {displayContent === "Setting" ? <Setting /> : ""}
                        {displayContent === "Review$" ? <Payout /> : ""}
                        {displayContent === "Position" ? <Position /> : ""}
                        {displayContent === "ReviewKYC" ? <ReviewKYC /> : ""}
                        {displayContent === "Upgrade" ? <Upgrade /> : ""}
                        {displayContent === "HFTPage" ? <HFTPage /> : ""}
                        {displayContent === "Promo" ? <Promo /> : ""}
                        {displayContent === "Partner" ? <Partner /> : ""}
                    </div>
                </div>
            }

        </div>
    )
}
export default Dashboard;
