import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, FormControl, InputLabel, Select, Box, Typography } from '@mui/material';
import axios from 'axios';

const CreateAccount = () => {
    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
    }
    const [email, setEmail] = useState('');
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [token, setToken] = useState(null);
    useEffect(() => {
        // Retrieve user session from localStorage
        const userSession = JSON.parse(localStorage.getItem("userSession"));
        if (userSession && userSession.token) {
            setToken(userSession.token);
        } else {
            console.log("No user session found");
            alert("No user session found");
        }
    }, []); // Runs only on component mount
    useEffect(() => {
        if (!token) return; // Don't make API call if no token is set

        axios.get(`${URL}/api/get-plans`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                console.log("Plans:", response.data);
                setPlans(response.data.plan);

                if (response.data.plan.length > 0) {
                    setSelectedPlan(response.data.plan[0]._id); // Fix: Correct array access
                }
            })
            .catch(error => {
                console.error("Error fetching plans:", error);
            });
    }, [token]); // Runs when `token` is set

    useEffect(() => {
        const timer = setTimeout(() => {
            if (email) {
                // Retrieve user session from localStorage
                const userSession = JSON.parse(localStorage.getItem("userSession"));
                const token = userSession ? userSession.token : null;

                if (!token) {
                    console.log("No user session found or token is missing.");
                    alert("You are not authenticated. Please log in again.");
                    return;
                }

                // Make authenticated request
                axios.post(
                    `${URL}/api/search`,
                    { email }, // Send email in the request body
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
                    .then(response => {
                        setUserOptions(response.data);
                        if (response.data.length === 1) {
                            setSelectedUser(response.data[0].email);
                        } else {
                            setSelectedUser('');
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching users:", error);
                    });
            }
        }, 500); // Debounce time is 500ms

        return () => clearTimeout(timer);
    }, [email]); // Effect runs whenever `email` state changes


const handleSubmit = (event) => {
    event.preventDefault();

    // Retrieve user session from localStorage
    const userSession = JSON.parse(localStorage.getItem("userSession"));
    const token = userSession ? userSession.token : null;

    if (!token) {
        console.log("No user session found or token is missing.");
        alert("You are not authenticated. Please log in again.");
        return;
    }

    axios.post(
        `${URL}/api/create-trading-account`,
        { email: selectedUser, planId: selectedPlan },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    )
    .then(response => {
        alert("Account created successfully");
    })
    .catch(error => {
        console.error("Error creating account:", error);
        alert("Failed to create account.");
    });
};


    return (
        <div className='overview-container'>
            <Box sx={{ maxWidth: 500, mx: 5 }}>
                <Typography variant="h5" gutterBottom>
                    Create Trading Account
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="User Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                {userOptions.length > 0 && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="user-label">Select User</InputLabel>
                        <Select
                            labelId="user-label"
                            id="user-select"
                            value={selectedUser}
                            label="Select User"
                            onChange={e => setSelectedUser(e.target.value)}
                        >
                            {userOptions.map((user) => (
                                <MenuItem key={user._id} value={user.email}>
                                    {user.firstName + " " + user.lastName || user.email}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <FormControl fullWidth margin="normal">
                    <InputLabel id="plan-label">Select Plan</InputLabel>
                    <Select
                        labelId="plan-label"
                        id="plan-select"
                        value={selectedPlan}
                        label="Select Plan"
                        onChange={e => setSelectedPlan(e.target.value)}
                    >
                        {plans !== null && plans.map((plan) => (
                            <MenuItem key={plan._id} value={plan._id}>
                                {plan.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                >
                    Create Account
                </Button>
            </Box>
        </div>

    );
};

export default CreateAccount;
