import React, { useState, useEffect } from 'react';
import {
    Button, TextField, Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent, CardActions, Modal
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

// Styled table component
const StyledTable = styled(Table)(({ theme }) => ({
    '& thead': {
        backgroundColor: theme.palette.primary.main,
        '& th': {
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
        },
    },
    '& tbody tr:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '& tbody tr:hover': {
        backgroundColor: theme.palette.action.selected,
        cursor: 'pointer',
    },
    '& td, & th': {
        padding: '12px',
        border: `1px solid ${theme.palette.divider}`,
        textAlign: 'left',
    },
}));

const Plans = () => {
    const [planName, setPlanName] = useState('');
    const [stages, setStages] = useState([]);
    const [plans, setPlans] = useState([]);
    const [currentStage, setCurrentStage] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [editingPlanId, setEditingPlanId] = useState(null); // Plan being edited
    const [stageDetails, setStageDetails] = useState({
        stageName: '',
        accountSize: '',
        dailyDrawdown: '',
        overallDrawdown: '',
        profitTarget: '',
        profitSplit: '',
        minimumTradingDays: '',
        totalDays: ''
    });

    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
    }

    const [token, setToken] = useState(null);
    useEffect(() => {
        // Retrieve user session from localStorage
        const userSession = JSON.parse(localStorage.getItem("userSession"));
        if (userSession && userSession.token) {
            setToken(userSession.token);
        } else {
            console.log("No user session found");
            alert("No user session found");
        }
    }, []); // Runs only on component mount

    useEffect(() => {
        // Fetch all existing plans
        axios.get(`${URL}/api/get-plans`)
            .then(response => {
                setPlans(response.data.plan);
            })
            .catch(error => console.error('Error fetching plans:', error));
    }, []);

    const handleStageChange = (e) => {
        const { name, value } = e.target;
        setStageDetails(prev => ({ ...prev, [name]: value }));
    };

    const addStage = () => {
        setStages(prevStages => [...prevStages, stageDetails]);
        setStageDetails({
            stageName: '',
            accountSize: '',
            dailyDrawdown: '',
            overallDrawdown: '',
            profitTarget: '',
            profitSplit: '',
            minimumTradingDays: '',
            totalDays: ''
        });
    };

    const savePlan = () => {
        if (!token) {
            console.log("No user session found");
            alert("You are not authenticated. Please log in again.");
            return;
        }

        const plan = { name: planName, stages };
        axios.post(`${URL}/api/create-plan`, plan, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.data.success) {
                    setPlanName('');
                    setStages([]);
                    setPlans(prev => [...prev, res.data.plan]);
                    alert("Plan added successfully");
                }
            })
            .catch(err => console.error("Error saving plan:", err));
    };

    const deletePlan = (planId) => {
        if (!token) {
            console.log("No user session found");
            alert("You are not authenticated. Please log in again.");
            return;
        }

        axios.delete(`${URL}/api/delete-plan/${planId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(() => {
                setPlans(prev => prev.filter(plan => plan._id !== planId));
            })
            .catch(err => console.error("Error deleting plan:", err));
    };


    const openEditModal = (planId, stage = null) => {
        setEditingPlanId(planId);
        setCurrentStage(stage);
        setStageDetails(stage || {
            stageName: '',
            accountSize: '',
            dailyDrawdown: '',
            overallDrawdown: '',
            profitTarget: '',
            profitSplit: '',
            minimumTradingDays: '',
            totalDays: ''
        });
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
        setCurrentStage(null);
    };

    const saveStage = () => {
        if (!editingPlanId || !stageDetails.stageName) {
            console.error("Missing plan ID or stage name");
            return;
        }

        axios
            .put(`${URL}/api/edit-stage/${editingPlanId}/${stageDetails.stageName}`, stageDetails)
            .then((response) => {
                const updatedPlan = response.data.plan;

                // Update the local state with the updated plan
                setPlans((prevPlans) =>
                    prevPlans.map((plan) =>
                        plan._id === updatedPlan._id ? updatedPlan : plan
                    )
                );

                closeModal(); // Close the modal on success
            })
            .catch((err) => console.error("Error saving stage:", err));
    };


    return (
        <Box sx={{ my: 4, mx: 2 }}>
            {/* Create a New Plan Section */}
            <Card sx={{ maxWidth: 700, mx: 'auto', p: 3, boxShadow: 3 }}>
                <CardContent>
                    <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 3 }}>
                        Create a New Plan
                    </Typography>
                    <TextField
                        label="Plan Name"
                        variant="outlined"
                        value={planName}
                        onChange={(e) => setPlanName(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <Typography variant="h5" sx={{ mt: 2 }}>
                        Add Stages
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", mx: "auto", mt: 2 }}>
                        {Object.keys(stageDetails).map(key => (
                            <TextField
                                key={key}
                                name={key}
                                label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                value={stageDetails[key]}
                                onChange={handleStageChange}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                sx={{ mb: 2 }}
                            />
                        ))}
                        <Button onClick={addStage} variant="contained" color="secondary" sx={{ mt: 1 }}>
                            Add Stage
                        </Button>
                    </Box>
                    <Button onClick={savePlan} variant="contained" color="primary" sx={{ mt: 3, width: '100%' }}>
                        Save Plan
                    </Button>
                </CardContent>
            </Card>

            {/* Stages Preview Section */}
            {stages.length > 0 && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Stages Preview
                    </Typography>
                    <TableContainer component={Paper} sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 3 }}>
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Stage Name</TableCell>
                                    <TableCell>Account Size</TableCell>
                                    <TableCell>Daily Drawdown</TableCell>
                                    <TableCell>Overall Drawdown</TableCell>
                                    <TableCell>Profit Target</TableCell>
                                    <TableCell>Profit Split</TableCell>
                                    <TableCell>Minimum Trading Days</TableCell>
                                    <TableCell>Total Days</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stages.map((stage, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{stage.stageName}</TableCell>
                                        <TableCell>{stage.accountSize}</TableCell>
                                        <TableCell>{stage.dailyDrawdown}</TableCell>
                                        <TableCell>{stage.overallDrawdown}</TableCell>
                                        <TableCell>{stage.profitTarget}</TableCell>
                                        <TableCell>{stage.profitSplit}</TableCell>
                                        <TableCell>{stage.minimumTradingDays}</TableCell>
                                        <TableCell>{stage.totalDays}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                </Box>
            )}

            {/* Display Existing Plans */}
            <Box sx={{ mt: 6 }}>
                <Typography variant="h5" gutterBottom>
                    Previous Plans
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap' }}>
                    {plans.map(plan => (
                        <Card key={plan._id} sx={{ mb: 3, maxWidth: 800 }}>
                            <CardContent>
                                <Typography variant="h6">{plan.name}</Typography>
                                <Typography variant="subtitle1">Stages:</Typography>
                                <TableContainer component={Paper} sx={{ mt: 2, borderRadius: 2, overflow: 'hidden' }}>
                                    <StyledTable>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Stage Name</TableCell>
                                                <TableCell>Account Size</TableCell>
                                                <TableCell>Daily Drawdown</TableCell>
                                                <TableCell>Overall Drawdown</TableCell>
                                                <TableCell>Profit Target</TableCell>
                                                <TableCell>Profit Split</TableCell>
                                                <TableCell>Minimum Trading Days</TableCell>
                                                <TableCell>Total Days</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {plan.stages.map((stage, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{stage.stageName}</TableCell>
                                                    <TableCell>{stage.accountSize}</TableCell>
                                                    <TableCell>{stage.dailyDrawdown}</TableCell>
                                                    <TableCell>{stage.overallDrawdown}</TableCell>
                                                    <TableCell>{stage.profitTarget}</TableCell>
                                                    <TableCell>{stage.profitSplit}</TableCell>
                                                    <TableCell>{stage.minimumTradingDays}</TableCell>
                                                    <TableCell>{stage.totalDays}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            sx={{ mr: 1 }}
                                                            onClick={() => openEditModal(plan._id, stage)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </StyledTable>
                                </TableContainer>
                            </CardContent>
                            <CardActions>
                                <Button size="small" variant="contained" color="error" onClick={() => deletePlan(plan._id)}>
                                    Delete Plan
                                </Button>
                            </CardActions>
                        </Card>
                    ))}
                </div>

            </Box>

            {/* Modal for Add/Edit Stage */}
            <Modal open={openModal} onClose={closeModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}>
                    <Typography variant="h6" gutterBottom>
                        {currentStage ? 'Edit Stage' : 'Add Stage'}
                    </Typography>
                    {Object.keys(stageDetails).map(key => (
                        <TextField
                            key={key}
                            name={key}
                            label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                            value={stageDetails[key]}
                            onChange={handleStageChange}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Button onClick={closeModal} variant="outlined" color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={saveStage} variant="contained" color="primary">
                            Save
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default Plans;
