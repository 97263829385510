import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

export default function Partner() {
  const [businessName, setBusinessName] = useState("");
  const [Email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [link, setLink] = useState("");
  const [partners, setPartners] = useState([]);
  const [alert, setAlert] = useState({ open: false, message: "", severity: "" });

  const [token, setToken] = useState(null);
  useEffect(() => {
      // Retrieve user session from localStorage
      const userSession = JSON.parse(localStorage.getItem("userSession"));
      if (userSession && userSession.token) {
          setToken(userSession.token);
      } else {
          console.log("No user session found");
          alert("No user session found");
      }
  }, []); // Runs only on component mount
  
  let URL;
  if (process.env.NODE_ENV === "production") {
    URL = "https://www.multiverse-funding.com";
  } else {
    URL = "http://localhost:8080";
  }

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await axios.get(`${URL}/api/partners`);
      setPartners(response.data);
    } catch (error) {
      setAlert({ open: true, message: "Error fetching partners", severity: "error" });
    }
  };

  const handleAddPartner = async () => {
    if (!token) {
        console.log("No user session found");
        alert("You are not authenticated. Please log in again.");
        return;
    }

    try {
        const newPartner = { businessName, image, link, Email };
        await axios.post(`${URL}/api/partners`, newPartner, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setAlert({ open: true, message: "Partner added successfully", severity: "success" });
        fetchPartners();
        setBusinessName("");
        setImage("");
        setLink("");
        setEmail("");
    } catch (error) {
      console.log(error);
        setAlert({ open: true, message: "Error adding partner", severity: "error" });
    }
};

const handleDeletePartner = async (id) => {
    if (!token) {
        console.log("No user session found");
        alert("You are not authenticated. Please log in again.");
        return;
    }

    try {
        await axios.delete(`${URL}/api/partners/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setAlert({ open: true, message: "Partner deleted successfully", severity: "success" });
        fetchPartners();
    } catch (error) {
        setAlert({ open: true, message: "Error deleting partner", severity: "error" });
    }
};


  return (
    <Box
      sx={{
        maxWidth: 600,
        mx: "auto",
        p: 3,
        mt: 4,
        backgroundColor: "white",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Manage Partners
      </Typography>

      {/* Add Partner Form */}
      <Box component="form" noValidate autoComplete="off" sx={{ mb: 4 }}>
        <TextField
          label="Business Name"
          fullWidth
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Email"
          fullWidth
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Image URL"
          fullWidth
          value={image}
          onChange={(e) => setImage(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Website Link"
          fullWidth
          value={link}
          onChange={(e) => setLink(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddPartner}>
          Add Partner
        </Button>
      </Box>

      {/* Partners Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Business Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Website Link</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partners.map((partner) => (
              <TableRow key={partner._id}>
                <TableCell>{partner.businessName}</TableCell>
                <TableCell>{partner.Email}</TableCell>
                <TableCell>
                  <img
                    src={partner.image}
                    alt={partner.businessName}
                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                  />
                </TableCell>
                <TableCell>
                  <a href={partner.link} target="_blank" rel="noopener noreferrer">
                    {partner.link}
                  </a>
                </TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeletePartner(partner._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
