import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField, IconButton, Paper, TableContainer, Button, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FlagIcon from '@mui/icons-material/Flag';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';
function TradingAccount({ sendData }) {
    const [accounts, setAccounts] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [violateOpen, setViolateOpen] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [newBalance, setNewBalance] = useState('');
    const [update, setUpdate] = useState(0);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://www.multiverse-funding.com";
    } else {
        URL = "http://localhost:8080";
        // URL = "https://www.multiverse-funding.com";

    }
    const [token, setToken] = useState(null);

    useEffect(() => {
        // Retrieve user session from localStorage
        const userSession = JSON.parse(localStorage.getItem("userSession"));
        if (userSession && userSession.token) {
            setToken(userSession.token);
        } else {
            console.log("No user session found");
            alert("No user session found");
        }
    }, []); // Runs only on component mount

    // Fetch trading accounts from the backend
    useEffect(() => {
        if (!token) {
            console.log("No user session found");
            return;
        }

        axios.get(`${URL}/api/find-all-trading-acccount`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setAccounts(response.data);
            })
            .catch(error => {
                console.error("Error fetching accounts:", error);
            });
    }, [token, update]);


    // Filter accounts based on search query
    const filteredAccounts = accounts.filter(account =>
        account.accountName.toLowerCase().includes(search.toLowerCase()) ||
        account.accountEmail.toLowerCase().includes(search.toLowerCase()) ||
        account.accountStatus.toLowerCase().includes(search.toLowerCase())
    );

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const status = {
        Active: "darkgreen",
        Passed: "darkblue",
        Violated: "darkred"

    }
    // Format numbers as localized strings
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number);
    };

    const handleEditOpen = (account) => {
        setCurrentAccount(account);
        setNewBalance(account.currentBalance);
        setEditOpen(true);
    };

    const handleDeleteOpen = (account) => {
        setCurrentAccount(account);
        setNewBalance(account.currentBalance);
        setDeleteOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };
    const handleViolateClose = () => {
        setViolateOpen(false);
    };
    const handleBalanceSave = () => {
        if (!token) {
            console.log("No user session found");
            alert("You are not authenticated. Please log in again.");
            return;
        }
    
        axios.post(
            `${URL}/api/update-account-balance`,
            { 
                accountId: currentAccount.accountId, 
                CurrentBalance: Number(newBalance), 
                Daily_threshold: Number(newBalance) 
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then(response => {
            setAccounts(accounts.map(account => 
                account.accountId === currentAccount.accountId 
                    ? { ...account, currentBalance: newBalance } 
                    : account
            ));
            setSnackbar({ open: true, message: 'Account edited successfully!', severity: 'success' });
            setUpdate(Math.floor(Math.random() * 1000) + 1);
            handleEditClose();
        })
        .catch(error => {
            console.error("Error updating account balance:", error);
            setSnackbar({ open: true, message: "Error editing balance", severity: "error" });
        });
    };
    
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };


    const deleteAccount = async () => {
        if (!token) {
            console.log("No user session found");
            alert("You are not authenticated. Please log in again.");
            return;
        }
    
        try {
            const response = await axios.delete(
                `${URL}/api/user/${currentAccount.accountEmail}/account/${currentAccount.accountId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
    
            if (response.data.success) {
                setAccounts(prevAccounts => prevAccounts.filter(account => account.accountId !== currentAccount.accountId));
                handleDeleteClose();
                setSnackbar({ open: true, message: 'Account Deleted successfully!', severity: 'success' });
            }
    
            return response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                console.error("Error response:", error.response.data);
                console.error("Status:", error.response.status);
                console.error("Headers:", error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error("Error request:", error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Error message:", error.message);
            }
            console.error("Error config:", error.config);
            return null;
        }
    };
    

    const ViewAccount = (account) => {
        sendData(account)
        // console.log(account)
    }
    const [formValues, setFormValues] = useState({
        reason: '',
        date: ''
    });
    const handleInputChange = (e) => {
        const { name, value } = e.target; // Get the name and value from the event target
        setFormValues({
            ...formValues, // Spread the existing state
            [name]: value // Update the changed value
        });
    };
    const ViolateAccount = (account) => {
        setCurrentAccount(account);
    
        if (!token) {
            console.log("No user session found");
            alert("You are not authenticated. Please log in again.");
            return;
        }
    
        if (violateOpen && formValues.reason !== "" && formValues.date !== '') {
            axios.post(
                `${URL}/api/violate-account`,
                { 
                    accountId: currentAccount.accountId, 
                    email: currentAccount.accountEmail, 
                    reason: formValues.reason, 
                    date: formValues.date 
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(res => {
                if (res.data.success) {
                    setSnackbar({ open: true, message: 'Account violated successfully', severity: 'success' });
                }
            })
            .catch(err => {
                alert("Something went wrong");
                console.log(err);
            });
        }
    
        setViolateOpen(true);
    };
    
    function filterActiveAccounts(accounts) {
        return accounts.filter(account => account.accountStatus === 'Active').length;
    }
    function filterPassedAccounts(accounts) {
        return accounts.filter(account => account.accountStatus === 'Passed').length;
    }
    function filterFailedAccounts(accounts) {
        return accounts.filter(account => account.accountStatus === 'Violated').length;
    }
    function filterDisabledAccounts(accounts) {
        return accounts.filter(account => account.accountStatus === 'Disabled').length;
    }
    function accountsInProfit(accounts) {
        return accounts.filter(account => account.CurrentBalance > account.InitialBalance && account.accountStatus === 'Active').length;
    }
    function accountsInLoss(accounts) {
        return accounts.filter(account => account.CurrentBalance < account.InitialBalance && account.accountStatus === 'Active').length;
    }
    function BreakEven(accounts) {
        return accounts.filter(account => account.CurrentBalance == account.InitialBalance && account.accountStatus === 'Active').length;
    }
    return (
        <div className='overview-container' style={{ padding: '20px' }}>
            <Card style={{ backgroundColor: "rgba(255, 255, 255, 1)", boxShadow: '0 4px 8px rgba(0, 0, 0, 0.25)' }} sx={{ width: 350, height: 200, margin: 2 }}>
                <CardContent>
                    <Typography color="text.secondary">
                        <div className='first_Container'>
                            <ul>
                                <li>Account Active</li>
                                <li>Account Failed</li>
                                <li>Account Passed</li>
                                <li>Account Disabled</li>
                                <li>Accounts in profit</li>
                                <li>Accounts in Loss</li>
                                <li>Accounts at Break even</li>
                            </ul>
                            <ul>
                                <li>{filterActiveAccounts(accounts)}</li>
                                <li>{filterFailedAccounts(accounts)}</li>
                                <li>{filterPassedAccounts(accounts)}</li>
                                <li>{filterDisabledAccounts(accounts)}</li>
                                <li>{accountsInProfit(accounts)}</li>
                                <li>{accountsInLoss(accounts)}</li>
                                <li>{BreakEven(accounts)}</li>
                            </ul>
                        </div>
                    </Typography>
                </CardContent>
            </Card>
            <TextField
                label="Search accounts..."
                variant="outlined"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: '20px' }}
            />
            <Paper style={{ backgroundColor: 'black', color: 'white' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ color: 'white' }}>Account ID</TableCell>
                                <TableCell style={{ color: 'white' }}>Account Name</TableCell>
                                <TableCell style={{ color: 'white' }}>Account Email</TableCell>
                                <TableCell style={{ color: 'white' }} >Account Status</TableCell>
                                <TableCell style={{ color: 'white' }}>Current Balance</TableCell>
                                <TableCell style={{ color: 'white' }}>Delete|Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredAccounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((account) => (
                                <TableRow key={account.accountId}>
                                    <TableCell style={{ color: 'white' }}>{account.accountId}</TableCell>
                                    <TableCell style={{ color: 'white' }}>{account.accountName}</TableCell>
                                    <TableCell style={{ color: 'white' }}>{account.accountEmail}</TableCell>
                                    <TableCell style={{ color: 'white' }}>
                                        <Chip

                                            sx={{
                                                backgroundColor: status[account.accountStatus],
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: status[account.accountStatus],
                                                },
                                                width: '80px', // Set a fixed width
                                                justifyContent: 'center' // Center the label text
                                            }}

                                            label={account.accountStatus} /></TableCell>
                                    <TableCell style={{ color: 'white' }}>${formatNumber(account.CurrentBalance)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteOpen(account)} color="secondary">
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleEditOpen(account)} color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => ViewAccount(account)} color="success">
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton onClick={() => ViolateAccount(account)} color="error">
                                            <FlagIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredAccounts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ color: 'white' }}
                />
            </Paper>

            {/* Edit Dialog */}
            <Dialog open={editOpen} onClose={handleEditClose}>
                <DialogTitle>Edit Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Account ID: {currentAccount?.accountId}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="balance"
                        label="New Balance"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={newBalance}
                        onChange={(e) => setNewBalance(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose}>Cancel</Button>
                    <Button onClick={handleBalanceSave}>Save</Button>
                </DialogActions>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} onClose={handleDeleteClose}>
                <DialogTitle>Delete Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confirm deleting acccount ID: {currentAccount?.accountId}
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>Cancel</Button>
                    <Button onClick={deleteAccount}>Confirm</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={violateOpen} onClose={handleViolateClose}>
                <DialogTitle>Violate Account</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ m: 1 }}
                        label='Reason'
                        fullWidth
                        name='reason' // Name used to update the state
                        value={formValues.reason}
                        onChange={handleInputChange}
                    />
                    <TextField
                        sx={{ m: 1 }}
                        type='date'
                        fullWidth
                        name='date' // Name used to update the state
                        value={formValues.date}
                        onChange={handleInputChange}
                    />
                    <DialogContentText sx={{ m: 1 }}>
                        Confirm violating  acccount ID: {currentAccount?.accountId}
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleViolateClose}>Cancel</Button>
                    <Button onClick={ViolateAccount}>Confirm</Button>
                </DialogActions>
            </Dialog>

            <div>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    message={snackbar.message}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            </div>
        </div>
    );
}

export default TradingAccount;
